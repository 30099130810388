





























import Vue from 'vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import { required, email } from 'vuelidate/lib/validators';
import { i18n } from '@/i18n';
import LoginRequest from '@/model/request/login-request';
import MaximLogo from '@/components/Menubar/MaximLogo.vue';
import PasswordField from '@/components/InputField/PasswordField.vue';
import Button from '@/components/Button/Button.vue';

export default Vue.extend({
  name: 'Form',
  components: {
    LoadingIndicator,
    MaximLogo,
    PasswordField,
    Button,
  },
  data() {
    return {
      form: {
        email: '',
        password: '',
        isValid: false,
      },
      submitted: false,
      loading: false,
      passwordShown: false,
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
    },
  },
  computed: {
    emailErrors() {
      const errors: string[] = [];
      if (this.submitted && !(this.$v.form.email as any).required) {
        errors.push(i18n.t('login.email-required').toString());
      } else if (this.submitted && !(this.$v.form.email as any).email) {
        errors.push(i18n.t('login.email-incorrect-format').toString());
      }
      return errors;
    },
  },
  methods: {
    setPassword(pass: string, valid: boolean) {
      this.form.password = pass;
      this.form.isValid = valid;
    },
    submitLogin() {
      this.submitted = true;
      this.$v.form.$touch();
      if (this.$v.$anyError || !this.form.isValid) {
        return;
      }
      this.loading = true;
      this.$store
        .dispatch('currentUserStorage/login', new LoginRequest(this.form.email, this.form.password))
        .finally(() => {
          this.loading = false;
        });
    },
  },
});
