










import Vue from 'vue';
import TopMenu from '@/components/Menubar/TopMenu.vue';
import Form from '@/components/Login/Form.vue';

export default Vue.extend({
  name: 'Login',
  components: {
    TopMenu,
    Form,
  },
  data() {
    return {
      loginImage: '/images/login-image.png',
    };
  },
});
