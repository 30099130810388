








import Vue from 'vue';
export default Vue.extend({
  name: 'Button',
  props: {
    icon: { type: String, default: '' },
    image: { type: String, default: '' },
    text: { type: String, default: '' },
    type: { type: String, default: '' },
    isLarge: { type: Boolean, default: false },
  },
});
